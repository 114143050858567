import { styled } from '@mui/system'
import { ChangeCountryLanguage } from '../../change-country-language'

const MiddleBottomWrapper = styled('div')`
	flex-wrap: wrap;
	padding: 0;
	background-color: ${({ theme }) => theme.palette.footer.bgMiddleBottom};
	color: ${({ theme }) => theme.palette.footer.color};
	display: flex;
	${({ theme }) => theme.breakpoints.up('md')} {
		padding: 25px 40px;
	}
	${({ theme }) => theme.breakpoints.up('lg')} {
		padding: 25px 176px;
	}
	& .footer-storelocator-link {
		width: auto;
		justify-content: center;
		padding: 0;
		border-bottom: none;
		font-size: ${({ theme }) => theme.typography.pxToRem(12)};
		border-radius: 0;
		font-family: var(--font-base-regular);
		min-height: 0;
		&:hover,
		&:active {
			border-bottom: none;
		}
	}
`

const ChangeCountryLanguageStyled = styled(ChangeCountryLanguage)`
	&.change-country-language-trigger-footer {
		flex-grow: 1;
		justify-content: center;
		border-bottom: 1px solid ${({ theme }) => theme.palette.footer.border};
		border-right: 1px solid ${({ theme }) => theme.palette.footer.border};
		padding: 30px 0;
		${({ theme }) => theme.breakpoints.up('md')} {
			flex-grow: 0;
			margin-right: 60px;
			width: auto;
			border-bottom: none;
			border-right: none;
			padding: 0;
		}
	}
`

export { ChangeCountryLanguageStyled, MiddleBottomWrapper }
