import { styled } from '@mui/system'

const BottomAreaWrapper = styled('div')`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: ${({ theme }) => theme.spacing(1)};
	a {
		color: ${({ theme }) => theme.palette.footer.color};
	}
	${({ theme }) => theme.breakpoints.up('md')} {
		border-top: 1px solid ${({ theme }) => theme.palette.footer.border};
		padding: 4px 40px;
		justify-content: flex-start;
	}
	${({ theme }) => theme.breakpoints.up('lg')} {
		padding: 4px 176px;
	}

	.MuiTypography-root {
		${({ theme }) => theme.breakpoints.down('md')} {
			font-size: ${({ theme }) => theme.typography.pxToRem(11)};
			line-height: 14px;
		}
	}
`

export { BottomAreaWrapper }
